<template>
	<div class="myAddressContent width-fill bg-fff" :class="themeClass" v-loading="pageLoading">
		<div class="rightContent">
			<el-row style="display:flex;margin-bottom: 24px;">
				<el-col :span="10" class="titleTop" style="font-size: 18px;font-weight: bold;margin-top:6px;">我的员工
				</el-col>
				<el-col :span="15" class="titleTop"
					style="font-size: 12px;text-align: cneter;color:#FFFFFF;flex-direction: row-reverse;">
					<div type="primary" class="addProBtn font-size14" @click="addCarBtn">新增员工</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="19" class="titleTop" style="font-size: 18px;width:100%">
					<el-input placeholder="请输入员工模糊查询" v-model="keyword">
						<i slot="suffix" class="el-input__icon el-icon-search" @click="getCarList"></i>
					</el-input>
				</el-col>
			</el-row>
			<div class="tabCont">
				<div class="carInfor" v-for="(item, index) of listData" :key="index" :data-index="index">
					<div class="carname">
						<span class="carnamespan"> {{item.fEmployeeName}}</span>
						<span>{{item.fMobilephone}}</span>
						<span class="background-color-theme enable-box radius2 font-size12"
							:class="item.fStatus == 0?'bg-ccc':''">{{item.fStatusName}}</span>
					</div>
					<div class="weight">
						<!-- <span class="cartype">{{item.fEmployeeFullName}}</span> -->
						<span>{{item.fPositionName}}</span>
					</div>
					<div class="editicon">
						<span class="iconfont editIconBtn font-size18 font-color-000 margin-r-25 pointer"
							@click="editbtn(item)">&#xe62b;</span>
						<span class="iconfont deletIconBtn font-size20 font-color-000 pointer"
							@click="deletebtn(item)">&#xe744;</span>
					</div>
				</div>
			</div>
			<div class="flex-row-center-center" v-if="listData.length == 0">
				<svg-icon icon-class="NoRightsAndInterests" />
			</div>
		</div>
		<div :class="themeClass" class="text-center">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page.sync="current" :page-size="pagesize" layout="prev, pager, next, jumper" :total="total"
				prev-text="上一页" next-text="下一页" :pager-count="5">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue'
	export default {
		data() {
			return {
				keyword: '',
				pageLoading: false,
				listData: [
					/* {
						name: 'one',
						id: 4,
						carname: '3新火车',
						personname: '刘璇开',
						telphone: '1519852623',
						big: '最大载重量',
						num: '20吨',
						checked: false,
						disabled: false,
						state: 1,
					} */
				],
				timeout: null,
				total: 10, //总数据
				currentPage: 1, //当前页
				pagesize: 8, //每页显示10条数据
			}

		},
		computed: {
			current: {
				get() {
					return this.currentPage
				},
				set() {}
			},
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			pagination
		},
		mounted() {
			this.getList();
		},
		methods: {
			//查询数据列表
			getList() {
				this.pageLoading = true;
				this.ApiRequestPost('/api/mall/sys/employee/get-list', {
					"fPostionID": 0,
					"maxResultCount": this.pagesize, //每页显示10条
					"filter": this.keyword,
					"sorting": "",
					"skipCount": (this.currentPage - 1) * this.pagesize, //当前页显示数据（跳过多少条数据）


					/* 					"fPostionID":0,
										"maxResultCount": 10, //每页显示10条
										"skipCount": 0, //当前页显示数据（跳过多少条数据） */
					/* fTransportToolTypeID: this.carToolType, */
				}).then(
					result => {
						this.pageLoading = false;
						this.listData = result.obj.items;
						this.total = result.obj.totalCount;

					},
					rej => {
						this.pageLoading = false;
						//this.$message({type: 'error',message: '获取数据失败',showClose: false,});
					}
				);
			},
			editbtn(item) {
				console.log(item, "itemlkd");
				this.$router.push({
					path: '/PersonalContent/newPersonbtn',
					query: {
						pageType: "编辑员工信息",
						fEmployeeID: item.fEmployeeID
					},
				});
			},

			//删除数据
			deletebtn(item) {
				const deleteList = [];
				const deleteObj = {
					"fEmployeeID": item.fEmployeeID,
					"frv": item.frv,
					"rowStatus": 1,
				};
				deleteList.push(deleteObj);
				this.$confirm('要删除信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost('/api/mall/sys/employee/delete-list', deleteList).then(
						result => {
							this.getList();
						},
						rej => {}
					);
				}).catch(() => {
					//this.$message({type: 'info',message: '已取消删除',showClose: false,});          
				});


			},
			//回车时查询
			getCarList() {
				this.getList()
			},
			//新增员工
			addCarBtn() {
				this.$router.push({
					path: '/PersonalContent/newPersonbtn',
					query: {
						pageType: "新增员工信息"
					},
				});
			},
			handleSizeChange(val) {
				this.$emit('handleSizeChange', val)
			},
			handleCurrentChange(val) {
				this.$emit('handleCurrentChange', val);
				this.currentPage = val;
				this.getList();
			}

		},
		watch: {
			carToolType(curVal, oldVal) {
				this.getList();
			}
		}
	};
</script>
<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	::v-deep .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-input__icon {
		line-height: 32px;
	}

	.myAddressContent {
		display: flex;
		flex-direction: column;
		padding: 0px 14px;
		margin-top: 10px;
		height: calc(100% - 10px);
		box-sizing: border-box;
	}

	.rightContent {
		height: 100%;
		width: 100%;
	}

	.editIconBtn {
		margin-right: 22px;
	}

	.titleTop {
		/* position: relative; */
		display: flex;
	}

	.addProBtn {
		width: 110px;
		height: 30px;
		background-color: #0173FE;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
		border-radius: 2px;
		margin-top: 13px;
		margin-left: 10px;
	}

	.manageBtn {
		background-color: #fff;
	}

	/* .el-input__inner {
		height: 32px;
		line-height: 32px;
	} */

	.tabCont {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// margin-right:7px;
		margin-top: 15px;
	}

	.carInfor {
		width: calc(50% - 9px);
		height: 70px;
		box-sizing: border-box;
		display: inline-block;
		background-color: #fff;
		padding: 15px 16px;
		border-radius: 10px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.0980392156862745);
		margin-bottom: 15px;

	}

	.carname {
		color: #000;
		font-weight: bold;
		font-size: 14px;
	}

	.carname span,
	.weight {
		display: inline-block;
	}

	.carname span {
		margin-right: 10px;
		vertical-align: middle;
	}

	.weight {
		font-size: 13px;
		color: #666;
	}

	.editicon {
		float: right;
		margin-top: -10px;
	}

	.editicon .u-icon:last-child {
		margin-left: 10px;
		margin-right: -2.5px;
	}

	.checkcar {
		margin-left: 40px;
		position: relative;
	}

	.checkmark {
		height: 14px;
		width: 14px;
		position: absolute;
		background-color: #0081FF;
		border-radius: 100px;
		left: -15px;
		top: 40%;
	}

	.cartype {
		display: inline-block;
		width: 60px;
		color: #0173FE;
		font-size: 12px;
	}

	.editIconBtn {
		margin-right: 22px;
	}

	/deep/ .el-form-item__label {
		text-align: left;
	}

	.enable-box {
		padding-left: 5px;
		padding-right: 5px;

	}
</style>
